  
.luckyCharm {}
.first1 {}
.first10 {}
.first100 {}
.mostTraded {}
.adminMinted {}
.mostValuable {}
.mostSearched {}
.mintCondition {}
.leet {}

.animate {
  animation : shimmer 2s infinite;
  background: linear-gradient(to right, #65658a59 4%,#65658a33 25%);
  background-size: 1000px 100%;
  border-radius: 7px;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
