
  .body::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge add Firefox */
  .body {
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
    display: none;
  }