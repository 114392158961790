@import url("//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css");

$colorOne: #7725ea;
$colorTwo: #00a3c4;
$white: #fff;
$gray: #9b9b9b;

.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 100%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  outline: 0;
  font-size: 1.3rem;
  color: $white;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    font-size: 1.3rem;
    cursor: text;
    top: 20px;
  }
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: $gray;
  pointer-events: none;
}

.form__field:focus {
  ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: $colorOne;
    font-weight:700;    
  }
  padding-bottom: 6px;  
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, $colorOne,$colorTwo);
  border-image-slice: 1;
}
/* reset input */
.form__field{
  &:required,&:invalid { box-shadow:none; }
}

.form__group .fa-dollar-sign {
  right: 440px;
  color: white;
}

.form__group .fa-dollar-sign { 
  position: absolute;
  bottom: 10px;
  right: 10px;
}

/* Hide number arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.gradient-button {
  margin: 20px 0;
  min-height: 50px;
  padding: 13px 24px;
  font-size: 1rem;
  line-height: 20px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  border: none;
  border-radius: 4px;
  outline: none;
  color: white;
  position: relative;
  border-radius: 26px;
  background-image: linear-gradient(90deg, $colorOne,$colorTwo);
  background-size: 400%;
  background-position: 0% 0%;
  text-decoration: none;
  
  &:hover {
    color: white;
    opacity: 0.8;
  }
}

.cveTokenListTable {
 text-align: left;
}

.cveTokenListRow {
  color: white;
  border: 10px solid transparent;
  cursor: pointer;
}

.cveTokenListRow td img {
  width: 60px;
  height: auto;
  
}

.cveTokenListRow td {
  border: none;
}

.spinner-border-lg span {
  display: none;
}

.errorText {
  color: red;
}

.cveImage {
  width: 30%;
  height: auto;
  margin-bottom: 3rem;
}

// .cvePrice {
//   text-align: left;
//   margin-top: 1.5rem;
//   color: gray;
//   font-size: 1rem;
//   font-weight: bold;
// }

.buttonDisabled {
  pointer-events: none;
  opacity: 0.6;
}

.offerTokenForm {
  margin-bottom: 200px;
}

.offerTokenForm .form__group {
  width: 80%;
  display: inline-block;
  padding-right: 5%;
  padding-bottom: 5%;

}

.offerTokenForm .btn {
  min-width: 15%;
  /* visibility: hidden; */
}


.NftCard {
  margin: 20px;
  background: transparent;
  height: 400px;
  width: 280px;
  font-family: Monospace;
  color: darkGray;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid gray;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(157, 96, 212, 0.5);
  background-image: linear-gradient(130deg, $colorOne, #7725ea4d, #282c34, #282c34, #282c34, #282c34);
  float: left;
}

.NftCard .card-title {
  color: white;
  font-size: 1.5rem;
  margin-top: 10px;
}

.NftCard .card-subtitle {
  margin-top: 5px;
}

.NftCard img {
  padding-left: 70px;
  padding-right: 70px;
}

.NftCard .card-footer {
  background: transparent;
  border-top: none;
}

.NftCard .card-text {
  font-size: 12px;
  max-height: 70px !important;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
}

.NftCardPriceText {
  color: white;
}

@media (max-width:320px)  {
  .hideOnMobile {
    display: none;
  }
  .expandOnMobile {
    width: 100%;
  }
  .marginBottomOnMobile {
    margin-bottom: 30px;
  }
}
@media (max-width:481px) and (min-width: 320px)  {
  .cveImage {
    width: 80%;
  }
  .hideOnMobile {
    display: none;
  }
  .expandOnMobile {
    width: 100%;
  }
  .marginBottomOnMobile {
    margin-bottom: 30px;
  }
}
@media (max-width:641px) and (min-width: 481px)   {
  .cveImage {
    width: 70%;
  }
  .hideOnMobile {
    display: none;
  }
  .expandOnMobile {
    width: 100%;
  }
  .marginBottomOnMobile {
    margin-bottom: 30px;
  }
}
@media (min-width:961px and (min-width: 641px))  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
@media (min-width:1281px) { /* hi-res laptops and desktops */ }