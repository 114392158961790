$easeInOutQuad: cubic-bezier(0.455, 0.030, 0.515, 0.955);

.leet #token-id {
  animation: neonGlow 1s infinite alternate $easeInOutQuad;
}

@keyframes neonGlow {
  0% {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8),
                 0 0 20px rgba(104, 214, 214, 0.8),
                 0 0 22px  rgba(84, 218, 218, 0.8),
                 0 0 40px  rgba(66,220,219,.8),
                //  0 0 40px  rgba(66,220,219,.8),   
  }
  100% {
    text-shadow: 0 0 2px rgba(255,255,255,.8),
                 0 0 20px rgba(104, 214, 214, 0.8),
                 0 0 10px rgba(84, 218, 218, 0.8),
                 0 0 20px rgba(66,220,219,.8),
                //  0 0 20px rgba(66,220,219,.8),           
  }
}

